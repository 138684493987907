import React, { useState } from 'react';
import axios from 'axios';

function FileUpload() {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert("파일을 선택해주세요.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post('http://localhost:8080/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert("파일이 성공적으로 업로드되었습니다.");
    } catch (error) {
      console.error("업로드 중 오류 발생:", error);
      alert("업로드 실패!");
    }
  };

  return (
    <div>
      <h2>JSONL 파일 업로드</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} accept=".jsonl" />
        <button type="submit">업로드</button>
      </form>
    </div>
  );
}

export default FileUpload;
